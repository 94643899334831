@import "~react-image-gallery/styles/css/image-gallery.css";
.App {
  text-align: center;
  background-color: #191919;
  min-width: 100vw;
  min-height: 100vh;

}
.App-header{

}
.main-header {
  background-color: #333333;
  display: flex;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.address{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  color: #fff;
  text-align: left;
}
.description{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  color: #fff;
  text-align: left;
}
.info-container{
  min-width: 100wh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 30px;
}